body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scf-main-logo {
  height: 70px;
  width: 70px;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 10px;
  margin-top: 8px;
  border-bottom: 1px solid rgb(225, 225, 225);
}

.logo-container h3 {
  padding: 0;
  margin: 0;
  color: #0082ba;
  font-size: 22px;
}

.logo-container h5 {
  padding: 0;
  margin: 0;
  color: #838383;
  font-size: 14px;
  font-weight: 600;
}

.domain-name {
  color: #0082ba;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.test-env {
  color: rgb(96, 96, 96);
  margin-bottom: 6px;
}

.powered-by {
  color: rgb(146, 146, 146);
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}

.bot-left-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 9px;
  height: 40px;
  width: 40px;
  background-color: #0082ba;
  border-radius: 150px;
  flex-direction: column;
}

.bot-left-icon-text {
  margin-bottom: 6px;
  margin-right: 0.5px;
}

.logo {
  height: 90px;
  width: 90px;
  outline: none;
  border: none;
}

.text-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 50px;
  margin-bottom: 10px;
}

.text-container p {
  margin: 0;
  line-height: 1.5;
  font-size: 14px;
}

.word {
  font-weight: bold;
  margin: 0;
  padding: 0;
  margin-right: 1px;
  font-size: 19.5px;
  color: #0082ba;
  line-height: 1.1;
}

.chat-parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 800px;
  background-color: white;
  overflow: hidden;
}

.logo-messages-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  overflow: hidden;
}

.message-container {
  flex: 1;
  overflow-y: auto;
  margin-right: -10px;
}

.message {
  display: flex;
  flex-direction: row;
  color: white;
  border-radius: 10px;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 8px;
  margin-bottom: 15px;
}

.user-message {
  justify-content: flex-end;
}

.bot-message {
  justify-content: flex-start;
}

.user-message p, .bot-message p {
  margin: 0;
  padding: 0;
}

.user-icon {
  height: 32px;
  width: 32px;
  margin-left: 8px;
  margin-top: 2px;
}

.scf-icon {
  height: 32px;
  width: 32px;
  margin-top: -4px;
}

.scf-logo-top {
  height: 65px;
}

.bot-chat-text, .user-chat-text {
  max-width: 70%;
  padding: 10px;
  padding-top: 0;
  border-radius: 8px;
  font-size: 16px;
  line-height: 1.5;
}

.bot-chat-text {
  background-color: #ffffff;
  color: #333;
}

.bot-chat-text > :first-child {
  margin-top: 0;
  padding-top: 0;
}

.user-chat-text {
  background-color: #f2f2f2;
  border: none;
  text-align: left;
  color: #000000;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 6px;
  border-radius: 10px;
}

.send-icon {
  font-size: 24px;
  transform: rotate(45deg);
}

.chat-input {
  display: flex;
  padding: 10px;
  background-color: transparent;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 10px;
  border: 1px solid #e0e0e0;
  outline: none;
}

button {
  padding: 0px 18px;
  background-color: #0082ba;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}

button:hover {
  background-color: #0296d5;
}

.loader {
  border: 4px solid rgba(243, 243, 243, 0.3);
  border-top: 4px solid #0082ba;
  border-radius: 50%;
  width: 25px;
  min-height: 25px;
  animation: spin 1.4s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .chat-parent {
    display: flex;
    height: 90vh;
    justify-content: center;
  }

  .user-message {
    max-width: 92%;
  }

  .logo-messages-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .text-container {
    margin-right: 0px;
  }

  .chat-input {
    width: 95%;
  }

  .bot-icon {
    height: 24px;
    min-width: 24px;
  }

  .scf-icon {
    width: 30px;
    height: 30px;
    margin-top: -2px;
  }

  .loader {
    margin-left: 10px;
  }

  .message-container {
    height: 83%;
  }
}