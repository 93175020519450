.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  padding: 0;
  overflow: hidden;
}

.modal-content {
  background: white;
  border-radius: 15px;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #0082ba;
  animation: slideUp 1s ease-in-out;
}

.modal-content h3 {
  margin: 0px;
  font-weight: bold;
  font-size: 1.4rem;
}

.modal-content h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  margin-top: 0px;
  font-weight: bold;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  font-size: 2.6rem;
}

.modal-content p {
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.6;
  margin: 0px;
  padding: 0;
}

.options {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.option-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.close-icon {
  color: #0082ba;
  font-size: 30px;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: transparent;
  transform: scale(1.2);
}

.header-logo-container {
  display: flex;
  align-items: center;
  gap: 0px;
}

.scf-logo {
  height: 50px;
  width: 50px;
  outline: none;
  border: none;
}

.agree-icon {
  margin-right: 10px;
  font-size: 22px;
  color: white;
}

.decline-icon {
  margin-right: 10px;
  font-size: 32px;
  color: rgb(159, 15, 15);
}

.modal-option-button {
  background: #0082ba;
  color: white;
  padding: 8px 10px;
  font-size: 22px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 160px;
}

.modal-option-button:hover {
  transform: scale(1.1);
}

.checklist {
  list-style: none;
  padding: 0;
  margin: 15px 0;
}

.checklist li {
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #2c2c2c;
  line-height: 1.5;
  position: relative;
  padding-left: 30px;
}

.checklist li .check-icon {
  position: absolute;
  left: 0;
  top: 0.2em;
  color: #0082ba;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .modal-content {
    width: 85%;
    margin-bottom: 70px;
  }
}
